<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 mx-auto">
        <div class="row mb-3">
          <div class="col">
            <p class="alert alert-success">
              To complete your health questionnaire, click <b>bookings</b> below and
              choose the related booking. Each stay with Amchara has a Health
              Questionnaire associated with it, if you have stayed with us in
              the past year, you will be able to duplicate your Health
              Questionnaire from that stay.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3 text-center">
            <router-link class="no-underline text-success" to="/bookings">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <i class="far text-success fa-calendar fa-3x mb-4"></i>
                  <h6 class="font-weight-bold mb-0">Bookings</h6>
                </div>
              </div></router-link
            >
          </div>

          <div class="col-6 mb-3 text-center">
            <div class="card border-0 shadow-sm">
              <router-link class="no-underline text-success" to="/treatments">
                <div class="card-body">
                  <i class="far text-success fa-syringe fa-3x mb-4"></i>
                  <h6 class="font-weight-bold mb-0">Treatments</h6>
                </div>
              </router-link>
            </div>
          </div>

          <!-- <div class="col-6 mb-3 text-center">
            <div class="card border-0 shadow-sm">
              <router-link class="no-underline text-success" to="/preferences">
                <div class="card-body">
                  <i class="far text-success fa-glass fa-3x mb-4"></i>
                  <h6 class="font-weight-bold mb-0">Preferences</h6>
                </div>
              </router-link>
            </div>
          </div> -->
          <!--
          <div class="col-6 mb-3 text-center">
            <div class="card border-0 shadow-sm">
              <router-link class="no-underline text-success" to="/360"
                ><div class="card-body">
                  <i class="far text-success fa-circle fa-3x mb-4"></i>
                  <h6 class="font-weight-bold mb-0">Amchara 360°</h6>
                </div></router-link
              >
            </div>
          </div> -->

          <div class="col-6 mb-3 text-center">
            <div class="card border-0 shadow-sm">
              <router-link class="no-underline text-success" to="/retreat-info">
                <div class="card-body">
                  <i class="far text-success fa-umbrella-beach fa-3x mb-4"></i>
                  <h6 class="font-weight-bold mb-0">Retreat Info</h6>
                </div>
              </router-link>
            </div>
          </div>

          <div class="col-6 mb-3 text-center">
            <div class="card border-0 shadow-sm">
              <router-link class="no-underline text-success" to="/contact">
                <div class="card-body">
                  <i class="far text-success fa-user fa-3x mb-4"></i>
                  <h6 class="font-weight-bold mb-0">Contact Info</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-3 text-center">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <img
                  src="@/assets/images/site_logo.png"
                  alt="Amchara"
                  width="50"
                  class="mb-3"
                />
                <h6 class="font-weight-bold mb-0">Amchara Website</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
